import orderBy from 'lodash/orderBy';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { openCreateFilteredsViewSubMenu, openEditViewSubMenu } from 'shared/foreground/cmdPalette';
import { globalState } from 'shared/foreground/models';
import { useFilteredViewsStats } from 'shared/foreground/stateHooks';
import {
  setSortViewsByKey,
  setSortViewsByOrder,
} from 'shared/foreground/stateUpdaters/clientStateUpdaters/sortManagement';
import {
  removeFilteredViews,
  updateFilteredView,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import {
  setFilteredViewIdToDelete,
  setFocusedDocumentId,
  setFocusedViewId,
} from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import useDocumentLocations from 'shared/foreground/utils/useDocumentLocations';
import useLiveValueRef from 'shared/foreground/utils/useLiveValueRef';
import useStatePlusLiveValueRef from 'shared/foreground/utils/useStatePlusLiveValueRef';
import { FilteredView, SortOrder, TableHeader, TableSortKey } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import getFormattedDurationFromNow from 'shared/utils/dates/getFormattedDurationFromNow';
import {
  getCategoriesIconMap,
  isAllowedToDeleteFilteredView,
  isAllowedToEditFilteredView,
} from 'shared/utils/filteredViews';
import removeEmoji from 'shared/utils/removeEmoji';
import useDebounce from 'shared/utils/useDebounce';

import { useKeyboardShortcutPreventDefault } from '../hooks/useKeyboardShortcut';
import useOnItemChecked from '../hooks/useOnItemChecked';
import useScrollIntoViewIfNeeded from '../hooks/useScrollIntoViewIfNeeded';
import { getFilteredViewPath } from '../utils/getFilteredViewPath';
import { useShortcutsMap } from '../utils/shortcuts';
import { draggableViewListItemPrefix, viewsListDroppableId } from '../utils/sidebar';
import BulkActionsHeader from './BulkActionsHeader';
import Button from './Button';
import { CustomCheckbox } from './Checkbox';
import { DeleteViewsDialog } from './DeleteViewsDialog';
import { FloatingPill } from './FloatingPill';
import MergeIcon from './icons/16StrokeMerge';
import TrashIcon from './icons/16StrokeTrash';
import ArticlesIconHeader from './icons/ArticlesIconHeader';
import BooksIconHeader from './icons/BooksIconHeader';
import EmailsIconHeader from './icons/EmailsIconHeader';
import NavFolderClosedIcon from './icons/NavFolderClosedIcon';
import PdfsIconHeader from './icons/PdfsIconHeader';
import StrokePinIcon from './icons/StrokePinIcon';
import StrokePinnedIcon from './icons/StrokePinnedIcon';
import TweetIconHeader from './icons/TweetIconHeader';
import VideosIconHeader from './icons/VideosIconHeader';
import LastUpdatedOrActionButtons, { DeleteButton, EditButton } from './LastUpdatedOrActionButtons';
import { MergeViewsDialog } from './MergeViewsDialog';
import SearchInput from './SearchInput';
import { ShowNavigationLeftPanelButton } from './ShowNavigationLeftPanelButton';
import { Table } from './Table';
import Tooltip from './Tooltip';
import styles from './ViewsList.module.css';

const ViewsBulkActionsHeader = React.memo(function ViewsBulkActionsHeader({
  onCheckedChange,
  views,
  isChecked,
  setUnfilteredSelectedIds,
  filteredSelectedIds,
  areAllItemsSelected = false,
}: {
  onCheckedChange: () => void;
  isChecked: boolean;
  setUnfilteredSelectedIds: (v: string[]) => void;
  filteredSelectedIds: string[];
  areAllItemsSelected: boolean;
  views: FilteredView[];
}) {
  const [isMergeDialogOpen, setIsMergeDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const deleteSelectedViews = useCallback(() => {
    removeFilteredViews(filteredSelectedIds, { userInteraction: 'click', showToast: true });
    setUnfilteredSelectedIds([]);
  }, [filteredSelectedIds, setUnfilteredSelectedIds]);

  const isMergeEnabled = filteredSelectedIds.length >= 2;

  return (
    <>
      <BulkActionsHeader
        selectedIds={filteredSelectedIds}
        setSelectedIds={setUnfilteredSelectedIds}
        resourceName="View"
        onCheckedChange={onCheckedChange}
        isChecked={isChecked}
        isMinusIcon={!areAllItemsSelected}
      >
        <Button
          className={`${styles.mergeButton} ${isMergeEnabled ? '' : styles.buttonDisabled}`}
          variant="secondary"
          onClick={() => setIsMergeDialogOpen(true)}
        >
          <MergeIcon /> Merge
        </Button>

        <Button
          className={styles.deleteButton}
          variant="secondary"
          onClick={() => setIsDeleteDialogOpen(true)}
        >
          <TrashIcon /> Delete
        </Button>
      </BulkActionsHeader>

      <MergeViewsDialog
        isOpen={isMergeDialogOpen}
        setIsOpen={setIsMergeDialogOpen}
        selectedIds={filteredSelectedIds}
        setSelectedIds={setUnfilteredSelectedIds}
        views={views}
      />

      <DeleteViewsDialog
        isOpen={isDeleteDialogOpen}
        onConfirm={deleteSelectedViews}
        count={filteredSelectedIds.length}
        onCancel={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
});

const ViewLastUpdatedOrActionButtons = React.memo(function _ViewLastUpdatedOrActionButtons({
  view,
  isFocused,
  deleteShortcut,
  onDelete,
  areSelectedItems,
}: {
  view: FilteredView & { count?: number; lastUpdate: number | null };
  isFocused: boolean;
  deleteShortcut: string | string[];
  onDelete: (id: string) => void;
  areSelectedItems: boolean;
}) {
  const lastUpdatedFromNow = view.lastUpdate
    ? getFormattedDurationFromNow(
        typeof view.lastUpdate === 'string' ? parseInt(view.lastUpdate as string, 10) : view.lastUpdate,
      )
    : '-';

  const handleOnDelete = useCallback(() => onDelete(view.id), [view.id, onDelete]);

  const togglePin = useCallback(() => {
    updateFilteredView(
      {
        ...view,
        isUnpinned: !view.isUnpinned,
      },
      { userInteraction: 'keypress' },
    );
  }, [view]);

  return (
    <LastUpdatedOrActionButtons
      lastUpdated={lastUpdatedFromNow}
      isFocused={isFocused}
      areSelectedItems={areSelectedItems}
    >
      {isAllowedToEditFilteredView(view) && <EditButton onClick={openEditViewSubMenu} />}

      {!view.rssFolderId && (
        <Tooltip content={view.isUnpinned ? 'Pin to sidebar' : 'Unpin from sidebar'}>
          <Button tabIndex={-1} onClick={togglePin}>
            {view.isUnpinned ? <StrokePinIcon /> : <StrokePinnedIcon />}
          </Button>
        </Tooltip>
      )}

      {isAllowedToDeleteFilteredView(view.id) && (
        <DeleteButton shortcut={deleteShortcut} onClick={handleOnDelete} />
      )}
    </LastUpdatedOrActionButtons>
  );
});

const categoriesIconMap = getCategoriesIconMap({
  articlesIcon: <ArticlesIconHeader />,
  emailsIcon: <EmailsIconHeader />,
  pdfsIcon: <PdfsIconHeader />,
  epubsIcon: <BooksIconHeader />,
  tweetsIcon: <TweetIconHeader />,
  videosIcon: <VideosIconHeader />,
});

interface ViewItemProps {
  areSelectedItems: boolean;
  deleteShortcut: string | string[];
  hasCheckboxCell: boolean;
  hasCheckboxElement: boolean;
  index: number;
  isChecked: boolean;
  isCmdPaletteOpen: boolean;
  isFocused: boolean;
  onCheckedChangeWithShiftInfo: ({
    isChecked,
    isShiftKey,
    id,
    index,
  }: { isChecked: boolean; isShiftKey: boolean; id: string; index: number }) => void;
  onDelete: (id: string) => void;
  setSelectedId: (id: string) => void;
  view: FilteredView & { count?: number; lastUpdate: number | null };
}

const ViewItem = React.memo(function _ViewItem({
  areSelectedItems,
  deleteShortcut,
  hasCheckboxCell,
  hasCheckboxElement,
  index,
  isChecked,
  isCmdPaletteOpen,
  isFocused,
  onCheckedChangeWithShiftInfo,
  onDelete,
  setSelectedId,
  view,
}: ViewItemProps) {
  const documentLocations = useDocumentLocations();
  const filteredViewPath = getFilteredViewPath(view, documentLocations);

  const itemRef = useRef<HTMLTableRowElement>(null);
  const headerHeight = 109;
  useScrollIntoViewIfNeeded(itemRef, isFocused, headerHeight);

  const setSelectedIdIfDropdownNotOpen = useCallback(() => {
    if (window.isRadixDropdownOpen || isCmdPaletteOpen) {
      return;
    }

    setSelectedId(view.id);
  }, [view, isCmdPaletteOpen, setSelectedId]);

  const onRowClick = useCallback(
    (e: React.MouseEvent) => {
      if (!onCheckedChangeWithShiftInfo || !areSelectedItems) {
        return;
      }

      onCheckedChangeWithShiftInfo({
        isChecked: !isChecked,
        isShiftKey: e.shiftKey,
        id: view.id,
        index,
      });
    },
    [onCheckedChangeWithShiftInfo, index, view.id, isChecked, areSelectedItems],
  );

  const onCheckedChange = useCallback(() => {
    onCheckedChangeWithShiftInfo({ isChecked: !isChecked, isShiftKey: false, id: view.id, index });
  }, [onCheckedChangeWithShiftInfo, view.id, index, isChecked]);

  return (
    <tr
      ref={itemRef}
      className={`${styles.item} ${isFocused ? styles.isFocused : ''} ${
        isChecked ? styles.isChecked : ''
      }`}
      onMouseOver={setSelectedIdIfDropdownNotOpen}
      onFocus={setSelectedIdIfDropdownNotOpen}
      onClick={onRowClick}
    >
      {hasCheckboxCell && (
        <td className={styles.checkboxWrapper}>
          {hasCheckboxElement && (
            <CustomCheckbox
              label={`view-${view.id}`}
              isChecked={isChecked}
              onCheckedChange={onCheckedChange}
            />
          )}
        </td>
      )}
      <Draggable
        draggableId={`${draggableViewListItemPrefix}${view.id}`}
        index={index}
        isDragDisabled={!view.isUnpinned || Boolean(view.rssFolderId)}
      >
        {(provided, snapshot) => {
          return (
            <td className={`${snapshot.isDragging ? styles.isDragging : ''}`}>
              <Link
                to={filteredViewPath}
                className={styles.name}
                style={provided.draggableProps.style}
                {...(provided?.draggableProps ?? {})}
                {...(provided?.dragHandleProps ?? {})}
                ref={provided.innerRef}
              >
                <span>
                  {categoriesIconMap[view.query] ? categoriesIconMap[view.query] : null}
                  {view.rssFolderId ? <NavFolderClosedIcon /> : null}
                  {view.name}
                </span>
              </Link>
            </td>
          );
        }}
      </Draggable>
      <td>
        <Link to={filteredViewPath} className={styles.description}>
          <span>{view.description || 'No description'}</span>
        </Link>
      </td>
      <td>
        <Link to={filteredViewPath} className={styles.query}>
          <Tooltip content={view.query}>
            <span>{view.query}</span>
          </Tooltip>
        </Link>
      </td>
      <td className={styles.count}>{typeof view.count === 'undefined' ? '-' : view.count}</td>
      <td className={styles.lastUpdated}>
        <ViewLastUpdatedOrActionButtons
          view={view}
          isFocused={isFocused}
          deleteShortcut={deleteShortcut}
          onDelete={onDelete}
          areSelectedItems={areSelectedItems}
        />
      </td>
    </tr>
  );
});

export const ViewsList = React.memo(function ViewsList() {
  const { viewsWithStats, hasFinishedLastUpdate, hasFinishedCounts } = useFilteredViewsStats();
  const viewsWithStatsRef = useLiveValueRef(viewsWithStats);

  const isViewSelectable = useCallback(
    function isViewSelectable(id: FilteredView['id']): boolean {
      const view = viewsWithStatsRef.current.find((item) => item.id === id);
      return Boolean(view) && isAllowedToDeleteFilteredView(id) && isAllowedToEditFilteredView(view);
    },
    [viewsWithStatsRef],
  );

  /*
    Some views can't be edited, merged, or deleted.
    We allow lower-level components to set a view as selected, but then filter them on a higher-level.
  */
  const [unfilteredSelectedIds, setUnfilteredSelectedIds, unfilteredSelectedIdsRef] =
    useStatePlusLiveValueRef<string[]>([]);
  const filteredSelectedIds = useMemo(
    () => unfilteredSelectedIds.filter((id) => isViewSelectable(id)),
    [isViewSelectable, unfilteredSelectedIds],
  );

  const isCmdPaletteOpen = globalState(useCallback((state) => state.cmdPalette.isOpen, []));
  const [searchQuery, setSearchQuery] = useState('');
  const focusedViewId = globalState(useCallback((state) => state.focusedViewId, []));
  const sortByKey = globalState(useCallback((state) => state.client.sortViewsByKey, []));
  const sortOrder = globalState(useCallback((state) => state.client.sortViewsByOrder, []));
  const shortcutsMap = useShortcutsMap();
  const hasFinishedLoadingStats = hasFinishedLastUpdate && hasFinishedCounts;

  // Doing this to prevent changing focus after creating a new view
  const debouncedIsCmdPaletteOpen = useDebounce(isCmdPaletteOpen, 500);
  const debouncedIsCmdPaletteOpenRef = useRef(false);

  useEffect(() => {
    debouncedIsCmdPaletteOpenRef.current = debouncedIsCmdPaletteOpen;
  }, [debouncedIsCmdPaletteOpen]);

  const filterFn = useCallback(
    (view: FilteredView) => {
      const matchesName = view.name && view.name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesDescription =
        view.description && view.description.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesQuery = view.query && view.query.toLowerCase().includes(searchQuery.toLowerCase());
      return Boolean(matchesName) || Boolean(matchesDescription) || Boolean(matchesQuery);
    },
    [searchQuery],
  );

  const filteredViews = useMemo(
    () => (searchQuery ? viewsWithStats.filter(filterFn) : viewsWithStats),
    [viewsWithStats, filterFn, searchQuery],
  );

  const areAnyViewsSelectable = useMemo(
    () => filteredViews.some((view) => isViewSelectable(view.id)),
    [filteredViews, isViewSelectable],
  );

  const orderedViews = useMemo(() => {
    if (sortByKey === TableSortKey.Manual) {
      return filteredViews;
    }

    if (sortByKey === TableSortKey.LastUpdated && !hasFinishedLastUpdate) {
      return filteredViews;
    }

    if (sortByKey === TableSortKey.Documents && !hasFinishedCounts) {
      return filteredViews;
    }

    return orderBy(
      filteredViews,
      [
        (view) => {
          switch (sortByKey) {
            case TableSortKey.Name:
              return removeEmoji({ input: view.name?.toLocaleLowerCase() || '' });
            case TableSortKey.Description:
            case TableSortKey.Query:
              return view[sortByKey]?.toLocaleLowerCase() || '';
            case TableSortKey.Documents: {
              return view.count ?? 0;
            }
            case TableSortKey.LastUpdated: {
              const lastUpdate = view.lastUpdate;

              if (!lastUpdate && sortOrder === SortOrder.Asc) {
                return Infinity;
              } else {
                return lastUpdate ?? 0;
              }
            }
          }
        },
      ],
      [sortOrder],
    );
  }, [filteredViews, sortByKey, sortOrder, hasFinishedLastUpdate, hasFinishedCounts]);

  const orderedViewsIds = useMemo(() => orderedViews.map((view) => view.id), [orderedViews]);

  const onHeaderCheckedChange = useCallback(() => {
    setUnfilteredSelectedIds((prev) => (prev.length ? [] : orderedViewsIds));
  }, [setUnfilteredSelectedIds, orderedViewsIds]);

  const onCheckedChangeWithShiftInfo = useOnItemChecked({
    selectedIds: unfilteredSelectedIdsRef.current,
    setSelectedIds: setUnfilteredSelectedIds,
    allIds: orderedViewsIds,
  });

  const onHeaderClick = useCallback(
    (key: TableSortKey) => {
      if (key === sortByKey) {
        setSortViewsByOrder(sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);
        return;
      }

      setSortViewsByKey(key);
    },
    [sortByKey, sortOrder],
  );

  useEffect(() => {
    return () => {
      setFocusedViewId(null);
    };
  }, []);

  useEffect(() => {
    if (focusedViewId) {
      return;
    }

    if (
      orderedViews.length > 0 &&
      !window.isRadixDropdownOpen &&
      !debouncedIsCmdPaletteOpenRef.current
    ) {
      setFocusedViewId(orderedViews[0].id);
    }

    setFocusedDocumentId(null, { userInteraction: 'unknown' });
  }, [orderedViews, focusedViewId]);

  const navItems = useCallback(
    (direction: number) => {
      if (isCmdPaletteOpen) {
        return;
      }

      const currentIndex = orderedViews.findIndex((view) => {
        return view.id === focusedViewId;
      });

      if (direction > 0) {
        if (orderedViews[currentIndex + 1]) {
          setFocusedViewId(orderedViews[currentIndex + 1].id);
        }
        return;
      }

      if (orderedViews[currentIndex - 1]) {
        setFocusedViewId(orderedViews[currentIndex - 1].id);
      }
    },
    [orderedViews, focusedViewId, isCmdPaletteOpen],
  );

  const onDelete = useCallback(
    (id: string) => {
      setFilteredViewIdToDelete(id);
      navItems(1);
    },
    [navItems],
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.Down],
    useCallback(() => navItems(1), [navItems]),
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.Up],
    useCallback(() => navItems(-1), [navItems]),
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.DeleteDocument],
    useCallback(() => {
      if (focusedViewId && isAllowedToDeleteFilteredView(focusedViewId)) {
        setFilteredViewIdToDelete(focusedViewId);
      }
    }, [focusedViewId]),
  );

  const tableHeaders = useMemo(
    () =>
      [
        {
          title: 'Name',
          sortkey: TableSortKey.Name,
        },
        {
          title: 'Description',
          sortkey: TableSortKey.Description,
        },
        {
          title: 'Query',
          sortkey: TableSortKey.Query,
        },
        {
          title: 'Documents',
          sortkey: TableSortKey.Documents,
          isLoading: !hasFinishedLoadingStats,
        },
        {
          title: 'Last Updated',
          sortkey: TableSortKey.LastUpdated,
        },
      ] as TableHeader[],
    [hasFinishedLoadingStats],
  );

  const areSelectedItems = useMemo(() => filteredSelectedIds.length > 0, [filteredSelectedIds]);

  return (
    <div className={`${styles.sources} ${areSelectedItems ? styles.areSelectedItems : ''}`}>
      <div className={styles.sourcesContainer}>
        <div className={styles.header}>
          <span className={styles.title}>
            <ShowNavigationLeftPanelButton />
            Filtered views
          </span>
          <div className={styles.headerRight}>
            <SearchInput setQuery={setSearchQuery} />
            <Button
              variant="default"
              className={styles.primary}
              onClick={openCreateFilteredsViewSubMenu}
            >
              Add filtered view
            </Button>
          </div>
        </div>

        <div className={`${styles.listContainer} has-visible-scrollbar`}>
          {areSelectedItems && (
            <ViewsBulkActionsHeader
              isChecked
              onCheckedChange={onHeaderCheckedChange}
              filteredSelectedIds={filteredSelectedIds}
              setUnfilteredSelectedIds={setUnfilteredSelectedIds}
              areAllItemsSelected={unfilteredSelectedIds.length === orderedViewsIds.length}
              views={viewsWithStats}
            />
          )}

          <table>
            {!areSelectedItems && (
              <Table.Header
                onHeaderClick={onHeaderClick}
                hasCheckbox={areAnyViewsSelectable}
                headers={tableHeaders}
                currentSortKey={sortByKey}
                currentSortOder={sortOrder}
                onCheckedChange={onHeaderCheckedChange}
                coverBorder={false}
              />
            )}

            <Droppable droppableId={viewsListDroppableId} type="pinned-views">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {orderedViews.map((view, index) => {
                    return (
                      <ViewItem
                        areSelectedItems={areSelectedItems}
                        deleteShortcut={shortcutsMap[ShortcutId.DeleteDocument]}
                        index={index}
                        hasCheckboxCell={areAnyViewsSelectable}
                        hasCheckboxElement={isViewSelectable(view.id)}
                        isChecked={filteredSelectedIds.includes(view.id)}
                        isCmdPaletteOpen={isCmdPaletteOpen}
                        isFocused={focusedViewId === view.id}
                        key={view.id}
                        onCheckedChangeWithShiftInfo={onCheckedChangeWithShiftInfo}
                        onDelete={onDelete}
                        setSelectedId={setFocusedViewId}
                        view={view}
                      />
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </div>

        <FloatingPill>
          <>Count: {orderedViews.length.toLocaleString()}</>
        </FloatingPill>
      </div>
    </div>
  );
});
